// import "./styles.css";
// import "./typeset.css";
// import "./markdown.sass";
// import typeset from "typeset";

// var main = document.querySelector("main.article").getInnerHTML();
// var main_t = typeset(main);
// document.querySelector("main.article").innerHTML = main_t;
import axios from "axios";
import marked from "marked";

import dj from "./lib/dj/";

var Han = require("han-css");
window.Han = Han;
//Han(document.querySelector("article")).render();

//const md_url = "https://github.com/xcatliu/typescript-tutorial/raw/master/advanced/generics.md";
// const md_url = "https://raw.githubusercontent.com/Javef/Markdown/master/README.md";

let darkMode = localStorage.getItem("darkMode");
let langMode = localStorage.getItem("langMode");

if (darkMode) {
  document.documentElement.classList.add("is-dark");
}

if (langMode === "zh") {
  document.documentElement.classList.add("is-lang-zh");
  document.documentElement.lang = "zh";
}

const walkTokens = (token) => {
  if (token.type === "text") {
    token.text = dj(token.text);
  }
};

const setDark = (dark) => {
  if (dark) {
    localStorage.setItem("darkMode", true);
    document.documentElement.classList.add("is-dark");
  } else {
    localStorage.setItem("darkMode", false);
    document.documentElement.classList.add("is-dark");
    document.documentElement.classList.remove("is-dark");
  }
};

const setLang = (lang) => {
  lang = lang ? "zh" : "en";
  localStorage.setItem("langMode", lang);
  langMode = lang;
  if (langMode === "zh") {
    document.documentElement.classList.add("is-lang-zh");
    document.documentElement.lang = "zh";
  } else {
    document.documentElement.classList.remove("is-lang-zh");
    document.documentElement.lang = "en";
  }
};

window.setDark = setDark;
window.setLang = setLang;

marked.use({ walkTokens });
marked.setOptions({
  highlight: function (code, lang) {
    const hljs = require("highlight.js");
    const language = hljs.getLanguage(lang) ? lang : "plaintext";
    return hljs.highlight(code, { language }).value;
  }
});

let location = window.location;
let previewForm = document.getElementById("previewform");
let url = location.search
  .substring(1)
  .replace(/\/\/github\.com/, "//raw.githubusercontent.com")
  .replace(/\/blob\//, "/");

const getBaseUrl = (search = false) => {
  let url = location.href;
  if (search) {
    url = location.search.slice(1);
  }
  url = url.split("/");
  url = url.slice(0, -1);
  return url.join("/") + "/";
};

const renderer = new marked.Renderer();
const originalRendererImage = renderer.image.bind(renderer);
const imageBaseUrl = getBaseUrl(true);
renderer.image = (href, title, text) => {
  href = imageBaseUrl + href;
  return originalRendererImage(href, title, text);
};

const proxy = "https://api.codetabs.com/v1/proxy/?quest=";
// axios.get(proxy + md_url).then(function (req) {
//   let md_ctx = req.data;
//   let md_html = marked(md_ctx);
//   document.getElementsByTagName("article")[0].innerHTML = md_html;
// });

if (url && url.indexOf(location.hostname) < 0) {
  axios
    .get(proxy + url)
    .then(function (req) {
      let md_ctx = req.data;
      let md_html = marked(md_ctx, { baseUrl: getBaseUrl(), renderer });
      document.getElementById("main").innerHTML = md_html;
      if (langMode === "zh") Han(document.getElementById("main")).render();
    })
    .catch(function (error) {
      console.error(error);
      previewForm.style.display = "block";
      previewForm.innerText = error;
    });
} else {
  previewForm.style.display = "block";
}
