export const tokenTypes = {
  ALPHABETS_AND_NUMBERS: "ALPHABETS_AND_NUMBERS",
  CJK: "CJK",
  ELLIPSIS: "ELLIPSIS",
  DOTS_AS_ELLIPSIS: "DOTS_AS_ELLIPSIS",
  TWO_DOTS: "TWO_DOTS",
  FULLWIDTH_LEFT_DOUBLE_QUOTE: "FULLWIDTH_LEFT_DOUBLE_QUOTE",
  FULLWIDTH_LEFT_PAREN: "FULLWIDTH_LEFT_PAREN",
  FULLWIDTH_LEFT_SINGLE_QUOTE: "FULLWIDTH_LEFT_SINGLE_QUOTE",
  FULLWIDTH_PUNCTUATION: "FULLWIDTH_PUNCTUATION",
  FULLWIDTH_RIGHT_DOUBLE_QUOTE: "FULLWIDTH_RIGHT_DOUBLE_QUOTE",
  FULLWIDTH_RIGHT_PAREN: "FULLWIDTH_RIGHT_PAREN",
  FULLWIDTH_RIGHT_SINGLE_QUOTE: "FULLWIDTH_RIGHT_SINGLE_QUOTE",
  INVALID_ELLIPSIS: "INVALID_ELLIPSIS",
  INVALID_ELLIPSIS_THREE: "INVALID_ELLIPSIS_THREE",
  MISC: "MISC",
  INVALID_DASH: "INVALID_DASH",
  NUMBERS: "NUMBERS",
  SUCCESSIVE_FULLWIDTH_EXCLAMATION: "SUCCESSIVE_FULLWIDTH_EXCLAMATION",
  WHITESPACE: "WHITESPACE",
  HALFWIDTH_CHAR: "HALFWIDTH_CHAR"
};

export const operationTypes = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  REPLACE: "REPLACE"
};
